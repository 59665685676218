import Popup from 'reactjs-popup'
import { useFactModalStore } from '../state/factModal'

const FactBox = () => {
  const open = useFactModalStore((state) => state.open)
  const setOpen = useFactModalStore((state) => state.setOpen)
  const closeModal = () => setOpen(false)
  const title = useFactModalStore((state) => state.title)
  const text = useFactModalStore((state) => state.text)
  const type = useFactModalStore((state) => state.type)

  return (
    <div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className='modal'>
          <a className='close' onClick={closeModal}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0' viewBox='0 0 512 512'
              height='2em'
              width='2em' xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z' />
            </svg>
          </a>
          <h1>{title}</h1>
          <hr />
          {type === 'video' &&
            <iframe
              className='video-frame'
                // width='560'
                // height='315'
              src={text}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />}
          {type === 'text' &&
            <p>{text}</p>}
        </div>
      </Popup>
    </div>
  )
}

export { FactBox }
