import create from 'zustand'

import data from '../data/facts.json'

const useFactModalStore = create((set) => ({
  open: false,
  setOpen: (value) => set(() => ({ open: value })),
  title: '',
  setTitle: (value) => set(() => ({ title: value })),
  text: '',
  setText: (value) => set(() => ({ text: value })),
  type: '',
  setType: (value) => set(() => ({ type: value })),
  setRandom: () => {
    const index = Math.round(Math.random() * (data.length - 1))
    set(() => ({ title: data[index].title }))
    set(() => ({ text: data[index].text }))
    set(() => ({ type: data[index].type }))
  }
}))

export { useFactModalStore }
